import SendOTP from "../SendOTP";
import VerifyOTP from "../VerifyOTP";
import {
  LoginContainer,
  LoginBanner,
  SignInContainer,
  LoginContent,
} from "assets/styles/main.styles";
import loginBanner from "assets/images/login.jpg";
import logo from "assets/images/logo.svg";
import Grid from "@mui/material/Grid";
import { useSearchParams, Link, useParams } from "react-router-dom";
import auth0 from "auth0-js";
import { Title } from "components";
import { useState, useEffect } from "react";
import { appUrl } from "config/constants";
import { decrypt } from "utils/encrypt";

export var webAuth = new auth0.WebAuth({
  domain: "auth.kapiree.com",
  clientID: "TSBSb0S9ZFeVQ1rwjspPql4gu0gLqkwW",
  redirectUri: appUrl + "/reviewer-interview-data",
  responseType: "token id_token",
});

export default function ReviewerLoginEnc() {
  let [searchParams] = useSearchParams();
  let data = searchParams.get("id");
  // let role = searchParams.get("role");
  let { id, role } = decrypt(data.replaceAll(" ", "+"));
  const [email, setEmail] = useState("");
  const [isMailSent, setIsMailSent] = useState(false);

  useEffect(() => {
    const idFromLocal = localStorage.getItem("candidateIdForExternal");
    const roleIdFromLocal = localStorage.getItem("jobRoleIdForExternal");
    if (!idFromLocal || id != idFromLocal) {
      localStorage.setItem("candidateIdForExternal", id);
    }
    if (!roleIdFromLocal || role != roleIdFromLocal) {
      localStorage.setItem("jobRoleIdForExternal", role);
    }
  }, [id, role]);

  return (
    <LoginContainer>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <LoginBanner src={loginBanner} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoginContent>
            <SignInContainer>
              <Link
                to="https://kapiree.com/"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <img src={logo} alt="logo" />
              </Link>
              <Title level="h1" text="External Reviewer Login" />
              {isMailSent ? (
                <VerifyOTP email={email} />
              ) : (
                <SendOTP
                  email={email}
                  setEmail={setEmail}
                  setIsMailSent={setIsMailSent}
                />
              )}
            </SignInContainer>
          </LoginContent>
        </Grid>
      </Grid>
    </LoginContainer>
  );
}
