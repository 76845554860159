import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Dialog, Table, Input } from "components";
import { TableButtonContainer, Box } from "assets/styles/main.styles";
import { Grid } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { getOrganizations, generateApiFromAdmin } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { FormatDate } from "../../../../../utils";
import {format } from "date-fns";
// import ViewTripleModal from "../ViewTripleModal";

const OrganizatrionListingTable = ({
  isCompleteList = true,
  filter,
  hideSearch,
  handleClickEdit,
  listUpdated,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const [showAPIGenModal, setShowAPIGenModal] = useState(false);
  const [selectedOrgDetails, setSelectedOrgDetails] = useState(null);
  const [apiKey, setApiKey] = useState("");

  const handleOpenAPIModal = (orgDetails) => {
    if (orgDetails?.apiKey != null) {
      setApiKey(orgDetails?.apiKey);
    } else {
      setApiKey("");
    }
    setShowAPIGenModal(true);
    setSelectedOrgDetails(orgDetails);
  };

  const handleButtonGeneration = async () => {
    generateApiFromAdmin(
      {
        email: selectedOrgDetails?.orgEmail,
        id: selectedOrgDetails?.id,
      },
      successCallback,
      errorCallback
    );
  };

  const successCallback = (data) => {
    setApiKey(data?.apiKey);
    showAlert("API generated successfully", "success");
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "orgLogo",
        Cell: (row) => {
          return (
            <div style={{ maxWidth: "100px" }}>
              <img
                src={row.row.original.orgLogo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "32px",
                  objectFit: "contain",
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "orgName",
        Cell: (row) => {
          return (
            <div
              className="table-nav-link"
              onClick={() => handleClickEdit(row.row.original.id)}
            >
              {row.row.original.orgName}
            </div>
          );
        },
      },
      // {
      //   Header: "NO. OF Recruiters",
      //   accessor: "noOfRecruiters",
      // },

      {
        Header: "Email",
        accessor: "orgEmail",
      },
      {
        Header: "CREATED ON",
        accessor: "createdDate",
        Cell: (row) => {
          const date = new Date(row.row.original.createdDate);
          return <>{row.row.original.createdDate ?  format(date, "PPp"): ""}</>;
        },
      },
      {
        id: "action",
        Header: "",
        accessor: "Action",
        Cell: (row) => {
          return (
            <>
              {row.row.original.isAssigned ? null : (
                <TableButtonContainer isFlex>
                  <Button
                    // startIcon={<ModeEditOutlineOutlinedIcon />}
                    size="small"
                    btnText="API key"
                    variant="text"
                    onClick={() => handleOpenAPIModal(row.row.original)}
                  />
                  <Button
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    size="small"
                    btnText="Edit"
                    variant="secondary"
                    onClick={() => handleClickEdit(row.row.original.id)}
                  />
                </TableButtonContainer>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [navigate, data]
  );

  const handleData = (res) => {
    setData(res);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getOrganizations(handleData, () =>
        showAlert("Failed to get organizations", "error")
      );
      setLoading(false);
    })();
  }, [listUpdated]);

  return (
    <div className="table-container">
      <Table
        columns={columns}
        data={isCompleteList ? data : data.slice(0, 5)}
        isLoading={loading}
        // hidePagination={isCompleteList ? false : true}
        defaultFilter={filter}
        hideSearch={hideSearch}
        hideFilter
        numOfLoadingBarCols={3}
      />
      {showAPIGenModal && (
        <Dialog
          size="md"
          open={showAPIGenModal}
          close={() => setShowAPIGenModal(false)}
          title={"API key generation"}
          children={
            <>
              <Box isGrey>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={10}>
                    <Input
                      label={""}
                      placeholder="Click generate button"
                      name="api_key"
                      value={apiKey}
                      isDisabled
                      className="apiKeyInput"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      btnText="Generate"
                      onClick={handleButtonGeneration}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />
      )}
    </div>
  );
};

export default OrganizatrionListingTable;
