import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ButtonIcon } from "assets/styles/main.styles";
import { Button } from "components";
import BadgeIcon from "@mui/icons-material/Badge";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import browsers from "../../../assets/images/browser.png";
import { logoClip } from "../../../config/constants";
const linkStyles = {
  textDecoration: "solid",
  color: "#4286F5",
  cursor: "pointer",
};

export default function AnswersNavbar({
  resumeFile,
  kycFile,
  candidateData,
  statusId,
  handleResumePreview,
  handleShareButton,
  roleDetails,
  handleKycPreview,
  setKycModalOpen,
  onBasicAnswerNavClicked,
}) {
  const location = useLocation();
  const { pathname } = location;
  const url = pathname.split("/");
  const fromReviewer = url[1] === "reviewer-interview-data";
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="left"
      sx={{ marginBottom: "10px" }}
    >
      <Stack
        direction="row"
        spacing={{ xs: 1, sm: 2 }}
        flexWrap="wrap"
        justifyContent="flex-start"
        paddingTop={"30px"}
      >
        <ButtonIcon>
          <DescriptionOutlinedIcon
            sx={{
              color: resumeFile == null ? "lightgray" : "#4286F5",
              cursor: resumeFile == null ? "not-allowed" : "pointer",
            }}
          />
          <div disabled={resumeFile === null}>
            <div
              style={{
                textDecoration: "solid",
                marginTop: " 4px",
                color: resumeFile == null ? "lightgray" : "#4286F5",
                cursor: resumeFile == null ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (resumeFile != null) {
                  handleResumePreview();
                }
              }}
            >
              Resume
            </div>
          </div>
        </ButtonIcon>
        <ButtonIcon>
          <ContactPageOutlinedIcon
            sx={{
              color: kycFile == null ? "lightgray" : "#4286F5",
              cursor: kycFile == null ? "not-allowed" : "pointer",
            }}
          />
          <div
            style={{
              textDecoration: "solid",
              marginTop: " 4px",
              color: kycFile == null ? "lightgray" : "#4286F5",
              cursor: kycFile == null ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              if (kycFile != null) {
                handleKycPreview(kycFile);
              }
            }}
          >
            KYC
          </div>

          {roleDetails?.isKycRequired && candidateData?.KycPool?.length > 0 && (
            <Tooltip placement="top" title={<>KYC</>} arrow>
              <Button
                startIcon={<BadgeIcon sx={{ opacity: "0.5" }} />}
                variant="text"
                btnText="KYC"
                onClick={() => setKycModalOpen(true)}
              />
            </Tooltip>
          )}
        </ButtonIcon>
        <ButtonIcon>
          <WhatsAppIcon />
          <a
            style={linkStyles}
            href={"https://wa.me/91" + candidateData?.phoneNumber}
            target="_blank"
          >
            {candidateData?.phoneNumber}
          </a>
        </ButtonIcon>
        <ButtonIcon>
          <MailOutlineIcon />
          <a style={linkStyles} href={"mailto:" + candidateData?.email}>
            {candidateData?.email}
          </a>
        </ButtonIcon>
        <ButtonIcon>
          <KeyboardDoubleArrowDownOutlinedIcon />
          <a style={linkStyles} onClick={onBasicAnswerNavClicked}>
            Basic Answers
          </a>
        </ButtonIcon>

        {candidateData?.device && (
          <Tooltip title={candidateData?.device} placement="top">
            <IconButton style={{ marginTop: "-10px" }}>
              {" "}
              {candidateData?.device === "Desktop" ? (
                <DesktopWindowsOutlinedIcon alt="Desktop" />
              ) : (
                <SmartphoneOutlinedIcon alt="Mobile" />
              )}
            </IconButton>
          </Tooltip>
        )}

        {candidateData?.location && (
          <Tooltip title={candidateData?.location} placement="top">
            <IconButton style={{ marginTop: "-10px", marginLeft: "0px" }}>
              <LocationOnOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* <Tooltip title={broswerName} placement="top-start">
					<IconButton style={{ marginTop: "-10px", width: "40px", marginLeft: "0px" }}>
						<div></div>
					</IconButton>
				</Tooltip> */}

        {candidateData?.browser && (
          <img
            src={browsers}
            alt="browser"
            height={"25px"}
            width={"auto"}
            style={{
              position: "relative",
              cursor: "pointer",
              marginTop: "-2px",
              clipPath: logoClip[candidateData?.browser]?.clip,
              marginLeft: logoClip[candidateData?.browser]?.x,
            }}
          />
        )}
      </Stack>

      {!fromReviewer && (
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          justifyContent="flex-end"
          paddingTop={"30px"}
        >
          {statusId === 2 || statusId === 3 ? (
            <ButtonIcon>
              <ShareOutlined />
              <a
                style={{
                  textDecoration: "solid",
                  color: "#4286F5",
                }}
                href={"javascript:void(0)"}
                onClick={handleShareButton}
              >
                Share
              </a>
            </ButtonIcon>
          ) : null}
        </Stack>
      )}
    </Grid>
  );
}
