const IS_DEVELOPMENT = window.location.href.includes("http://local") ;

export const appUrl = IS_DEVELOPMENT ? "http://local.auth:3000" : "https://app.kapiree.com";
export const baseUrl = IS_DEVELOPMENT ? "http://localhost:3002" : "https://api.kapiree.com";
export const candidateAppUrl = IS_DEVELOPMENT
  ? "http://local.auth:3000/candidateLogin"
  : "https://app.kapiree.com/candidateLogin";

export const CANDIDATE = "CANDIDATE";
export const RECRUITER = "RECRUITER";
export const ADMIN = "ADMIN";
export const getPaginationArr = () => [
  { id: 5, optionText: 5 },
  { id: 10, optionText: 10 },
  { id: 20, optionText: 20 },
]
export const questionTypes = [
  {
    id: "option a",
    optionText: "Normal",
  },
  // {
  //   id: 'option b',
  //   optionText: 'Code snippet',
  // },
  {
    id: "option c",
    optionText: "Surprise",
  },
];

export const timeLimitOptions = [
  {
    id: "option a",
    optionText: 0.5,
  },
  {
    id: "option b",
    optionText: 1,
  },
  {
    id: "option c",
    optionText: 2,
  },
  {
    id: "option d",
    optionText: 3,
  },
  {
    id: "option e",
    optionText: 4,
  },
  {
    id: "option f",
    optionText: 5,
  },
];

export const retakeOptions = [
  {
    id: "option a",
    optionText: 0,
  },
  {
    id: "option b",
    optionText: 1,
  },
  {
    id: "option c",
    optionText: 2,
  },
  {
    id: "option d",
    optionText: 3,
  },
];

export const programmingLanguageOptions = [
  {
    id: "option a",
    optionText: "Java",
  },
  {
    id: "option b",
    optionText: "Javascript",
  },
  {
    id: "option c",
    optionText: "C#",
  },
  {
    id: "option d",
    optionText: "candidatePreference",
  },
];

export const keyName = ["name", "Name", "NAME", "FULL NAME", "Full Name"];

export const keyEmail = ["email", "Email", "EMAIL", "E-mail", "E-MAIL", "e-mail"];

export const keyCountryCode = ["countryCode", "Country Code", "country code"];

export const keyMobileNo = [
  "mobile number",
  "mobile",
  "mobileNumber",
  "mobileNo",
  "Mobile No",
  "Mobile Number",
  "MOBILE NO",
  "phone",
  "phoneNo",
  "phone number",
];

export const errorMessages = {
  required: "This field is required",
  invalid: "Invalid input value",
  fileSize: "File size should be less than 5MB",
  email: "Enter a valid email",
  onlyAlphablets: "Please enter only alphabets",
};

export const statuses = {
  0: "Request Interview",
  1: "Requested",
  2: "Pending Review",
  3: "Fit",
  4: "Maybe",
  5: "Rejected",
  6: "Attempted",
};

export const RatingValues = {
  1: "Didnot Liked It",
  2: "It was Okay",
  3: "Liked It",
  4: "Really Liked It",
  5: "It was Amazing",
};

export const questionType = {
  NORMAL: "Normal",
  CODE_SNIPPET: "Code snippet",
  HIDDEN_QUESTION: "Hidden question",
  Camera: "Camera",
};

export const recordingStatus = {
  STAND_BY: "standBy",
  START_RECORDING: "startRecording",
  STOP_RECORDING: "stopRecording",
};

export const logoClip = {
  Safari: { clip: "inset(0% 0% 0% 80%)", x: "-90px" },
  Opera: { clip: "inset(0% 20% 0% 60%)", x: "-60px" },
  Firefox: { clip: "inset(0% 40% 0% 40%)", x: "-40px" },
  "Google Chrome": { clip: "inset(0% 80% 0% 0%)", x: "10px" },
  Edge: { clip: "inset(0% 60% 0% 20%)", x: "-10px" },
  Default: { clip: "inset(0% 0% 0% 0%)", x: "-60px" },
};
