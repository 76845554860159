import React, { useCallback } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import MinimalLayout from "layout/MinimalLayout";
import MainLayout from "layout/MainLayout";

import GlobalStyleOverrides from "theme/GlobalStyleOverrides";

import CandidateInterview from "pages/Candidate/CandidateInterview";
import { Uikit, Login, CandidateOverview, InterviewDashboard } from "pages";
import { PrivateRouteComponent, GuestRouteComponent } from "config/auth-guard";
import { routes } from "config/routes";
import { UserProvider, useUser } from "contexts/user.context";
import { AlertProvider } from "contexts/alert.context";
import { InterviewProvider } from "contexts/interview.context";
import CandidateLogin from "pages/Candidate/CandidateLogin/CandidateLogin";
import CandidataKYC from "pages/Candidate/CandidateKYC";
import CandidateInfo from "pages/Interviewer/CandidateInfo";
import VidrecButton from "pages/VidrecButton";
import Appreciation from "pages/Interviewer/Appriciation/Appriciation";
import CandidateBaseQuestions from "pages/Interviewer/CandidateInfo/CandidateBaseQuestions";
import CandidateVideos from "pages/Interviewer/CandidateVideos";
import { InterviewDataProvider } from "contexts/interviewData";
import CandidateOverviewError from "pages/Candidate/CandidateOverview/Error";
import ReviewerLogin from "pages/Reviewer/Login";
import InterviewDatas from "pages/Reviewer/Review";
import ReviewerLoginEnc from "pages/ReviewerEnc/Login";
import { jwtDecode } from "jwt-decode";

const RouteSection = () => {
  const { userDetails, isLoading } = useUser();

  const privateRouteFunction = (roles, child) => {
    return (
      <PrivateRouteComponent
        roles={[roles]}
        shouldAuthenticate={true}
        child={child}
      />
    );
  };

  const guestRouteFunction = (child) => {
    return <GuestRouteComponent child={child} />;
  };

  const routingFunction = useCallback(() => {
    return routes.map((item) => {
      let element = item.isAuthorized
        ? privateRouteFunction(item.role, item.component)
        : item.component;

      return <Route path={item.path} exact element={element} />;
    });
  }, [userDetails]);

  if (window.location?.pathname?.includes("vidrec-button")) {
    return <VidrecButton />;
  }

  function isExpired(token) {
    if (token == null) return true;
    let decoded = jwtDecode(token);
    // console.log(Date.now() >= decoded?.exp * 1000);
    if (Date.now() >= decoded?.exp * 1000) {
      return true;
    }
    return false;
  }

  const AuthWrapper = () => {
    return isExpired(localStorage.getItem("idToken")) ? (
      <Navigate to="/login" replace />
    ) : (
      <Outlet />
    );
  };

  return (
    <Routes>
      <Route element={<AuthWrapper />}>
        <Route element={<MainLayout />}>{routingFunction(routes)}</Route>
      </Route>
      <Route element={<MinimalLayout />}>
        <Route path="/" exact element={guestRouteFunction(<Login />)} />
        <Route path="/uikit" element={<Uikit />} />
      </Route>
      <Route path="/login" element={guestRouteFunction(<Login />)} />
      <Route path="/invite" element={<CandidateLogin />} />
      <Route path="/login-failed" element={<CandidateOverviewError />} />
      {/* <Route path="/candidate-interview" element={<CandidateInterview />} />
      <Route path="/candidate-interview/kyc" element={<CandidataKYC />} /> */}
      <Route path="/info-candidate" element={<CandidateInfo />} />
      <Route path="/reg-submit" element={<Appreciation />} />
      <Route path="/candidate-videos" element={<CandidateVideos />} />
      <Route path="/reviewer-login" element={<ReviewerLogin />} />
      <Route path="/reviewer-login-enc" element={<ReviewerLoginEnc />} />
      <Route path="/reviewer-interview-data" element={<InterviewDatas />} />
      <Route
        path="/candidateLogin"
        element={guestRouteFunction(<CandidateOverview />)}
      />
      {/* <Route
        path="/candidate-basequestions"
        element={<CandidateBaseQuestions />}
      /> */}
      <Route path="*" to={guestRouteFunction(<Login />)} />
      {/* <Route path="/test" element={<CandidateInterview />} /> */}
    </Routes>
  );
};

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={GlobalStyleOverrides()}>
        <AlertProvider>
          <UserProvider>
            <InterviewProvider>
              <InterviewDataProvider>
                <RouteSection />
              </InterviewDataProvider>
            </InterviewProvider>
          </UserProvider>
        </AlertProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
