import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import {
  Title,
  Input,
  Checkbox,
  CheckboxListing,
  DragItemList,
  Button,
} from "components";
import {
  Box,
  BlockMargin,
  TooltipTitle,
  TooltipText,
  CreateJobContainer,
  CandidateWrapper,
  CandidateItemList,
  CandidateItem,
} from "assets/styles/main.styles";
import HelpIcon from "@mui/icons-material/Help";
import {
  addOrganizationInfo,
  createJobRoles,
  getOrgDetails,
  // getJoborganizationDetails,
  updateJobRoles,
} from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { errorMessages } from "config/constants";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { webAuth } from "config/auth-config";

const CreateJob = ({ handleClose, id }) => {
  const initialInterviewQuestionData = {
    sortOrder: 1,
    question: "",
    timeLimit: 2,
    retake: 0,
    type: "Surprise",
    cutOf: 0,
  };
  const [roleMoreInfo, setRoleMoreInfo] = useState({ notFit: 0, fit: 5 });
  const [organizationDetails, setOrganizationDetails] = useState({});
  const interviewQuestionsRef = useRef([initialInterviewQuestionData]);
  const { showAlert } = useAlert();
  const [fieldErrors, setFieldErrors] = useState({});
  const [interviewValidationError, setInterviewValidationError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recruiterList, setRecruiterList] = useState([{}]);
  const [fieldError, setFieldError] = useState(null);
  const [logo, setLogo] = useState(null);
  const [recruterAuth0Id, setRecruterAuth0Id] = useState(null);

  const handleOrganizationData = (e) => {
    validateInput(e);
    setOrganizationDetails({
      ...organizationDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitError = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
  };

  const handleCreateSuccess = (res) => {
    showAlert("Successfully created", "success");
    addRecruiterDataToAuth0(recruiterList, res.password);
    closeFunction();
  };

  const handleUpdateSuccess = () => {
    showAlert("Successfully updated", "success");
    closeFunction();
  };

  const convertToFormData = (data) => {
    const newData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          for (const [subKey, subValue] of Object.entries(item)) {
            newData.append(`data[${key}][${subKey}]`, subValue);
          }
        }
      } else {
        newData.append(key, value);
      }
    }
    newData.append("userId", recruterAuth0Id);
    return newData;
  };

  const handleSubmit = async () => {
    let userData = null;
    let data = {
      ...organizationDetails,
      recruiters: recruiterList,
    };
    let formData = convertToFormData(data);
    formData.append("logo", logo);
    id && formData.append("orgId", id);
    if (data.orgName != "" && data.orgEmail != "") {
      setIsSubmitting(true);
      await addOrganizationInfo(
        formData,
        id ? handleUpdateSuccess : handleCreateSuccess,
        handleSubmitError
      );
    } else {
      setIsSubmitting(false);
      handleSubmitError("Please fill mandatory fields.");
    }
    setIsSubmitting(false);
  };

  const handleSetOrganizationDetails = (data) => {
    setOrganizationDetails(data);
  };

  const handleOrgApiSuccessData = (data) => {
    let temp = { ...data };
    delete temp.recriter;
    delete temp.orgLogo;
    setOrganizationDetails(temp);
    setRecruiterList(
      data.recriter.map((item) => {
        return {
          id: item.id,
          fullName: item.name,
          email: item.email,
          mobileNumber: item.phoneNumber,
        };
      })
    );
  };

  const handleOrgApiFailData = (data) => {
    console.log("zrk", data);
  };

  useEffect(() => {
    if (id) {
      getOrgDetails(id, handleOrgApiSuccessData, handleOrgApiFailData);
    } else {
      handleSetOrganizationDetails({
        orgName: "",
        orgLogo: "",
        orgEmail: "",
      });
    }
  }, [id]);

  const closeFunction = () => {
    handleClose();
  };

  const validateInput = (e) => {
    if (e.target.name) {
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.required,
        });
      } else if (
        e.target.name === "fit" &&
        (e.target.value <= roleMoreInfo.notFit || e.target.value > 5)
      ) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else if (
        e.target.name === "notFit" &&
        (e.target.value >= roleMoreInfo.fit || e.target.value < 1)
      ) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else {
        let temp = { ...fieldErrors };
        delete temp[e.target.name];
        setFieldErrors(temp);
      }
    }
  };

  const addRecruiter = () => {
    setRecruiterList([...recruiterList, {}]);
  };

  const deleteRecruiter = (i) => {
    let temp = [...recruiterList];
    temp.splice(i, 1);
    setRecruiterList(temp);
  };

  const handleRecruiterData = (e, i) => {
    let temp = [...recruiterList];
    temp[i][e.target.name] = e.target.value;
    setRecruiterList(temp);
  };

  const handleFileUpload = (e) => {
    const sizeInBytes = e.target.files[0].size;
    const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    setLogo(e.target.files[0]);
    let temp = { ...fieldError };
    delete temp.resume;
    if (sizeInMB > 5) {
      temp = { ...temp, resume: errorMessages.fileSize };
    }
    setFieldError(temp);
  };

  const addRecruiterDataToAuth0 = async (
    recruiterListData,
    password = "Neo@1234"
  ) => {
    let temp = [...recruiterListData];
    for (let index = 0; index < temp.length; index++) {
      let recruiter = temp[index];
      if (recruiter.email != undefined) {
        webAuth.signup(
          {
            email: recruiter.email,
            scope: "openid profile email offline_access",
            connection: "Username-Password-Authentication",
            username: recruiter.fullName,
            userMetadata: { role: "RECRUITER" },
            password,
          },
          async (err, res) => {
            if (res) {
              setRecruterAuth0Id(res.Id);
            }
            if (err) {
              handleSubmitError(err.description);
            }
            // webAuth.changePassword(
            //   {
            //     email: recruiter.email,
            //     connection: "Username-Password-Authentication",
            //   },
            //   (response, error) => {
            //     console.log(error);
            //     console.log(response);
            //   }
            // );
          }
        );
        setRecruiterList(temp);
      }
    }
  };

  return (
    <CreateJobContainer>
      <BlockMargin></BlockMargin>
      <BlockMargin>
        <Box isGrey>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={6}>
              <Input
                label={
                  <>
                    Organization Name <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder="Enter Name"
                name="orgName"
                onChange={handleOrganizationData}
                value={organizationDetails?.orgName}
                onBlur={validateInput}
                error={!!fieldErrors["orgName"]}
                errorText={fieldErrors["orgName"]}
              />
            </Grid>
            <Grid item xs={6}>
              {organizationDetails.orgLogo ? (
                <img src={organizationDetails.orgLogo} alt="logo" />
              ) : (
                <Input
                  label={
                    <>
                      Organization Logo (jpg, png)
                      <span style={{ color: "red" }}>* </span>
                    </>
                  }
                  placeholder="Enter Organization Logo"
                  name="logo"
                  onChange={handleFileUpload}
                  type="file"
                  onBlur={validateInput}
                  error={!!fieldErrors["logo"]}
                  errorText={fieldErrors["logo"]}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Domain Name"
                placeholder="Enter Domain"
                name="orgDomain"
                onChange={handleOrganizationData}
                value={organizationDetails?.orgDomain}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={
                  <>
                    Organization Email <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder="Enter Email"
                name="orgEmail"
                onChange={handleOrganizationData}
                error={!!fieldErrors["orgEmail"]}
                errorText={fieldErrors["orgEmail"]}
                onBlur={validateInput}
                value={organizationDetails?.orgEmail}
                readOnly={id != null}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Contact Number"
                placeholder="Enter Phone"
                name="orgPhone"
                onChange={handleOrganizationData}
                // error={!!fieldErrors["orgPhone"]}
                // errorText={fieldErrors["orgPhone"]}
                // onBlur={validateInput}
                value={organizationDetails?.orgPhone}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Organization Address"
                placeholder="Enter Address"
                name="orgAddress"
                onChange={handleOrganizationData}
                value={organizationDetails?.orgAddress}
              />
            </Grid>
          </Grid>
        </Box>
      </BlockMargin>
      <CandidateWrapper>
        <Title level="h4" text="Recruiter List" />
        <Box isGrey>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Title
                level="label"
                text={
                  <>
                    Full name <span style={{ color: "red" }}>*</span>
                  </>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Title
                level="label"
                text={
                  <>
                    Email <span style={{ color: "red" }}>*</span>
                  </>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Title
                level="label"
                text={
                  <>
                    Mobile <span style={{ color: "red" }}>*</span>
                  </>
                }
              />
            </Grid>
          </Grid>
          <CandidateItemList>
            {recruiterList.map((item, i) => (
              <CandidateItem key={item.id}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className="candidate-list"
                >
                  <Grid item xs={4}>
                    <Input
                      name="fullName"
                      value={item.fullName}
                      onChange={(e) => handleRecruiterData(e, i)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      name="email"
                      value={item.email}
                      onChange={(e) => handleRecruiterData(e, i)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={3}>
                        <Input
                          type="text"
                          placeholder={"+91"}
                          name="countryCode"
                          defaultValue={"+91"}
                          pattern="[^(\+?\d[0-9]{3})]"
                          onChange={(e) => handleRecruiterData(e, i)}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Input
                          type="number"
                          placeholder={"Mobile number"}
                          name="mobileNumber"
                          value={item.mobileNumber}
                          onChange={(e) => handleRecruiterData(e, i)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteRecruiter(i)}
                        >
                          {i !== 0 && <DeleteIcon />}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CandidateItem>
            ))}
          </CandidateItemList>
        </Box>
        {/* <Grid container item xs={12} justifyContent="flex-end">
          <Button
            startIcon={<AddIcon />}
            btnText="Add New Recruiter"
            className="add-button"
            onClick={addRecruiter}
          />
        </Grid> */}
      </CandidateWrapper>
      <Grid
        container
        item
        xs={12}
        columnSpacing={3}
        paddingTop={2}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <Button
            variant="outlined"
            btnText="Cancel"
            onClick={() => closeFunction()}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            btnText="Submit"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
          />
        </Grid>
      </Grid>
    </CreateJobContainer>
  );
};

export default CreateJob;
