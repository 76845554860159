import { BlockMargin } from "assets/styles/main.styles";
import { Input, Button } from "components";
import { webAuth } from "../Login";
import { useAlert } from "contexts/alert.context";
import { useState } from "react";

export default function VerifyOTP({ email }) {
  const [otp, setOtp] = useState("");
  const { showAlert } = useAlert();
  function handleOTPVerify() {
    if (otp && email) {
      try {
        webAuth.passwordlessLogin(
          {
            connection: "email",
            email: email,
            verificationCode: otp,
          },
          function (err, res) {
            if (err) {
              console.log("Not able to verify the OTP");
              showAlert(err, "error");
              return;
            }
            console.log(err);
            console.log(res);
          }
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      showAlert("Please enter OTP", "error");
    }
  }

  return (
    <BlockMargin size={30}>
      <Input
        label="Email Address"
        placeholder="Enter Email Address"
        name="email"
        value={email}
        type="email"
        readOnly
        isDisabled={true}
        style={{ marginBottom: "10px" }}
      />
      <Input
        label="OTP"
        placeholder="Enter OTP"
        name="otp"
        value={otp}
        type="number"
        onChange={(e) => setOtp(e.target.value)}
      />
      <Button
        variant="contained"
        btnText="Verify OTP"
        onClick={handleOTPVerify}
        style={{ marginTop: "1rem" }}
      />
    </BlockMargin>
  );
}

// redirect
// return navigate("/interview-data2", {
//   state: {
//     id,
//     role,
//   },
// });
//   webAuth.parseHash(
//     { hash: window.location.hash },
//     function (err, authResult) {
//       if (err) {
//         return console.log(err);
//       }

//       webAuth.client.userInfo(
//         authResult.accessToken,
//         function (err, user) {
//           // Now you have the user's information
//         }
//       );
//     }
//   );
