import { useAlert } from "contexts/alert.context";
import { BlockMargin } from "assets/styles/main.styles";
import { Input, Button } from "components";
import { webAuth } from "../Login";

export default function SendOTP({ email, setEmail, setIsMailSent }) {
  const { showAlert } = useAlert();
  function sendOTP() {
    const emailRegExp = /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
    if (email && emailRegExp.test(email)) {
      webAuth.passwordlessStart(
        {
          connection: "email",
          send: "code",
          email: email,
        },
        function (err, res) {
          // handle errors or continue
          if (err) {
            console.log("Not able to sent the OTP");
            showAlert(err, "error");
            return;
          }
          setIsMailSent(true);
        }
      );
    } else {
      showAlert("Enter a valid Email ID", "error");
    }
  }

  return (
    <BlockMargin size={30}>
      <Input
        label="Email Address"
        placeholder="Enter Email Address"
        name="email"
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant="contained"
        btnText="Send OTP"
        onClick={sendOTP}
        style={{ marginTop: "1rem" }}
      />
    </BlockMargin>
  );
}
