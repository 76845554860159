import React, { useState } from "react";
import { Grid, FormControl, Input, InputLabel, Button } from "@mui/material";
import { Helmet } from "react-helmet";

import { Card, Modal, PageHeader } from "components";
import OrganizatrionListingTable from "./components/OrganizatrionListingTable";

import HireSvg from "assets/images/illustrations/hire.svg";
import ClientSvg from "assets/images/illustrations/client.svg";
import InterviewSvg from "assets/images/illustrations/interview.svg";
import AddIcon from "@mui/icons-material/Add";

import { Box, CardBlock } from "assets/styles/main.styles";
import CreateOrganization from "./components/CreateOrganization";
import EmbedCode from "./components/EmbedCode";

const OrgListing = () => {
  const [open, setOpen] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [listUpdated, setListUpdated] = useState(false);
  const [embedOpen, setEmbedOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedOrgId(null);
  };

  const handleClickEdit = (id) => {
    setOpen(true);
    setSelectedOrgId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrgId(null);
    setListUpdated(!listUpdated);
  };
  const handleCloseEmbed = () => {
    setEmbedOpen(false);
    setSelectedOrgId(null);
  };

  return (
    <div style={{ padding: "1rem" }}>
      <PageHeader
        buttonIcon={<AddIcon />}
        pageTitleText="Organization Listings"
        btnText="Add New Organization"
        onClick={handleClickOpen}
      />
      <Box>
        <OrganizatrionListingTable
          isCompleteList
          handleClickOpen={handleClickOpen}
          handleClickEdit={handleClickEdit}
          listUpdated={listUpdated}
        />
      </Box>
      {open && (
        <Modal
          size="md"
          open={open}
          close={handleClose}
          title={`${
            selectedOrgId != null ? "Update" : "Create New"
          } Organization`}
          children={
            <CreateOrganization handleClose={handleClose} id={selectedOrgId} />
          }
        />
      )}
    </div>
  );
};

export default OrgListing;
